import {
  createBaseThemeOptions,
  createUnifiedTheme,
  defaultTypography,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';
import SandvikSans from '../assets/fonts/SandvikSansText-Regular.woff2';

const sandvikSans = {
  fontFamily: 'Sandvik-Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('SandvikSans'),
    url(${SandvikSans}) format('woff2'),
  `,
};

export const darkTheme = createUnifiedTheme({
  fontFamily: 'Sandvik-Sans',
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [sandvikSans],
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        // Expected to only affect starred, top, and recent entities lists
        root: {
          '& a': {
            color: '#dddddd',
            textDecoration: 'none'
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          height: 'auto',
          '.homepage &': {
            height: '100%',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(115,100,75,0.8)',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#242424',
        },
      },
    },
    BackstageHeader: {
      styleOverrides: {
        title: () => ({
          color: "#FFFFFF",
          fontSize: '1.3rem',
        }),
        subtitle: () => ({
          color: "#FFFFFF",
        }),
      },
    },
    BackstageSidebar: {
      styleOverrides: {
        drawer: {
          borderRight: '#BEAF93',
          borderRightWidth: 'medium',
          borderRightStyle: 'groove',
        }
      }
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          fontWeight: 'normal',
        },
        label: {
          fontWeight: 'unset',
          transition: 'transform 0.3s ease-out',
          transformOrigin: 'left center',
          '&:hover': {
            transform: 'scale(1.2)',
            transition: 'transform 0s',
          },
        },
        selected: {
          "text-shadow": '.3px 0px .15px, -0.3px 0px .15px',
        },
      },
    },
    BackstageInfoCard: {
      styleOverrides: {
        header: {
          background: '#323232'
        }
      }
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontWeight: 'normal',
          color: '#FFFFFF',
        },
        selected: {
          fontWeight: 'bold',
        }
      }
    },
    BackstageBottomLink: {
      styleOverrides: {
        boxTitle: {
          color: '#FFFFFF',
        }
      }
    },
  },
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#BEAF93',
      },
      secondary: {
        main: '#F54B00',
      },
      error: {
        main: '#EB0D0D',
      },
      warning: {
        main: '#EB0D0D',
      },
      info: {
        main: '#1441F5',
      },
      success: {
        main: '#485e30',
      },
      background: {
        default: '#242424',
        paper: '#323232',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#565a6e',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      navigation: {
        background: '#242424',
        indicator: '#BEAF93',
        color: '#FFFFFF',
        selectedColor: '#BEAF93',
        navItem: {
          hoverBackground: '#242424',
        },
      },
    },
  }),
  typography: {
    ...defaultTypography,
    fontFamily: 'Sandvik-Sans',
    htmlFontSize: 16,
    h2: {
      fontSize: '1.25rem',
      fontWeight: 300,
      marginBottom: 10,
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: 300,
      marginBottom: 10,
    },
    h4: {
      fontSize: '1.15rem',
      fontWeight: 300,
      marginBottom: 10,
    },
    h5: {
      fontSize: '1.1rem',
      fontWeight: 300,
      marginBottom: 10,
    },
    h6: {
      fontSize: '1.05rem',
      fontWeight: 300,
      marginBottom: 10,
    },
  },
  defaultPageTheme: 'home',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#010101', '#73644b'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#010101', '#73644b'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#010101', '#73644b'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#010101', '#73644b'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#010101', '#73644b'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#010101', '#73644b'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#010101', '#73644b'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#010101', '#73644b'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#010101', '#73644b'], shape: shapes.wave }),
  },
});