import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { TemplateSandvikLogoIcon } from '../../assets/logos/TemplateSandvikLogoIcon';


const useStyles = makeStyles(() => ({
  path: {
    fill: useTheme().palette.navigation.color
  },
}));

const LogoIcon = (props: {
  classes: {
    svg: string;
  };
}) => {
  const { path } = useStyles();

  return (
    <TemplateSandvikLogoIcon classes={{ svg: props.classes.svg, path: path }} />
  );
};

export default LogoIcon;