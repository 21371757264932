import {
  createBaseThemeOptions,
  createUnifiedTheme,
  defaultTypography,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';
import SandvikSans from '../assets/fonts/SandvikSansText-Regular.woff2';

const sandvikSans = {
  fontFamily: 'Sandvik-Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('SandvikSans'),
    url(${SandvikSans}) format('woff2'),
  `,
};

export const lightTheme = createUnifiedTheme({
  fontFamily: 'Sandvik-Sans',
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [sandvikSans],
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#000000',
            backgroundColor: '#F8F8F8',
            borderRadius: '10px',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        // Fix for links in starred entities not using theme link color
        root: {
          '& a': {
            color: '#101010',
            textDecoration: 'none',
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#EBE6DC',
          },
        },
      },
    },
    BackstageHeader: {
      styleOverrides: {
        title: () => ({
          color: "#010101",
          fontSize: '1.3rem',
        }),
        subtitle: () => ({
          color: "#000000",
        }),
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          fontWeight: 'normal',
        },
        label: {
          fontWeight: 'unset',
          transition: 'transform 0.3s ease-out',
          transformOrigin: 'left center',
          '&:hover': {
            transform: 'scale(1.2)',
            transition: 'transform 0s',
          },
        },
        selected: {
          "text-shadow": '.3px 0px .15px, -0.3px 0px .15px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          height: 'auto',
          '.homepage &': {
            height: '100%',
          },
        }
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(115,100,75,0.4)',
        },
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontWeight: 'normal',
          color: '#000000',
        },
        selected: {
          fontWeight: 'bold',
        }
      }
    },
    BackstageBottomLink: {
      styleOverrides: {
        boxTitle: {
          color: '#000000',
        }
      }
    }
  },
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#A08C64',
      },
      secondary: {
        main: '#73644B',
      },
      error: {
        main: '#EB0D0D',
      },
      warning: {
        main: '#EB0D0D',
      },
      info: {
        main: '#1441F5',
      },
      success: {
        main: '#485e30',
      },
      background: {
        default: '#F8F8F8',
        paper: '#ffffff',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#101010',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      navigation: {
        background: '#EBE6DC',
        indicator: '#73644B',
        color: '#101010',
        selectedColor: '#73644B',
        navItem: {
          hoverBackground: '#EBE6DC',
        },
      },
      text: {
        primary: '#101010',
        secondary: '#343b58',
        disabled: '#9D9D9D',
        hint: '#434343',
      },
    },
  }),
  typography: {
    ...defaultTypography,
    fontFamily: 'Sandvik-Sans',
    htmlFontSize: 16,
    h2: {
      fontSize: '1.25rem',
      fontWeight: 300,
      marginBottom: 10,
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: 300,
      marginBottom: 10,
    },
    h4: {
      fontSize: '1.15rem',
      fontWeight: 300,
      marginBottom: 10,
    },
    h5: {
      fontSize: '1.1rem',
      fontWeight: 300,
      marginBottom: 10,
    },
    h6: {
      fontSize: '1.05rem',
      fontWeight: 300,
      marginBottom: 10,
    },
  },
  defaultPageTheme: 'home',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#BEAF93', '#A08C64'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#BEAF93', '#A08C64'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#BEAF93', '#A08C64'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#BEAF93', '#A08C64'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#BEAF93', '#A08C64'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#BEAF93', '#A08C64'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#BEAF93', '#A08C64'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#BEAF93', '#A08C64'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#BEAF93', '#A08C64'], shape: shapes.wave }),
  },
});