import React from 'react';
import {
  PageWithHeader,
  Content,
  ItemCardGrid,
  Progress,
  WarningPanel,
  ContentHeader,
  SupportButton,
  EmptyState,
} from '@backstage/core-components';
import {
  CATALOG_FILTER_EXISTS,
  catalogApiRef,
} from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/esm/useAsync';
import { ComponentEntity } from '@backstage/catalog-model';
import { EntityCard } from '../EntityCard/EntityCard';
import { useApi } from '@backstage/core-plugin-api';
import { techdocsPlugin } from '@backstage/plugin-techdocs';

const Body = ({ tags }: { tags: string[] }) => {
  const catalogApi = useApi(catalogApiRef);
  const {
    value: entities,
    loading,
    error,
  } = useAsync(async () => {
    const response = await catalogApi.getEntities({
      filter: {
        kind: 'Component',
        'metadata.tags': tags,
        'metadata.annotations.backstage.io/techdocs-ref': CATALOG_FILTER_EXISTS,
      },
    });
    return response.items as ComponentEntity[];
  }, [catalogApi, tags]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return (
      <WarningPanel severity="error" title="Could not load Golden Paths">
        {error.message}
      </WarningPanel>
    );
  }

  if (!entities?.length) {
    return (
      <EmptyState
        missing="info"
        title="No Golden Paths to display"
        description="You haven't added any Golden Paths yet."
      />
    );
  }

  return (
    <ItemCardGrid>
      {entities.map((entity, index) => (
        <EntityCard
          key={index}
          entity={entity}
          routeRef={techdocsPlugin.routes.docRoot}
          linkText="Start"
          showTags={false}
        />
      ))}
    </ItemCardGrid>
  );
};

/**
 * Props for {@link GoldenPathsPage}
 *
 * @public
 */
export type GoldenPathsPageProps = {
  tags?: string[]; 
};

export const GoldenPathsPage = (props: GoldenPathsPageProps) => {
  const { tags = ["golden-path"] } = props;
  return (
    <PageWithHeader themeId="other" title="Golden Paths">
      <Content>
        <ContentHeader title="">
          <SupportButton>Get started at Sandvik.</SupportButton>
        </ContentHeader>
        <Body tags={tags} />
      </Content>
    </PageWithHeader>
  );
};
