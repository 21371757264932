import { Entity, RELATION_OWNED_BY } from '@backstage/catalog-model';
import {
  EntityRefLinks,
  entityRouteParams,
  getEntityRelations,
  entityRouteRef
} from '@backstage/plugin-catalog-react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import React from 'react';

import { LinkButton, ItemCardHeader } from '@backstage/core-components';
import { useRouteRef, RouteRef } from '@backstage/core-plugin-api';


/**
 * Props for {@link EntityCard}
 *
 * @public
 */
export interface EntityCardProps {
  entity: Entity;
  routeRef?: RouteRef;
  linkText?: string;
  showTags?: boolean;
}


export const EntityCard = (props: EntityCardProps) => {
  const { entity, routeRef = entityRouteRef, linkText = "Explore", showTags = true } = props;

  const entityRoute = useRouteRef(routeRef);
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
  const url = entityRoute(entityRouteParams(entity));

  const owner = (
    <EntityRefLinks
      entityRefs={ownedByRelations}
      color="inherit"
    />
  );

  return (
    <Card>
      <CardMedia>
        <ItemCardHeader
          title={entity.metadata.title ?? entity.metadata.name}
          subtitle={owner}
        />
      </CardMedia>
      <CardContent>
        {showTags && entity.metadata.tags?.length ? (
          <Box>
            {entity.metadata.tags.map(tag => (
              <Chip size="small" label={tag} key={tag} />
            ))}
          </Box>
        ) : null}
        {entity.metadata.description}
      </CardContent>
      <CardActions>
        <LinkButton to={url} color="primary">
          { linkText }
        </LinkButton>
      </CardActions>
    </Card>
  );
};
